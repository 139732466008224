@import "./src/scss/abstracts/variables";
$homepage-card-duration: 0.3s;
.citations{
    .arrow-link-down{
        text-decoration: none;
        @include transition($nav-link-transition);
        &:hover{
            color:$premier!important;
          
        }
        
    }
    &-inner{
        max-height:0;
        overflow: hidden;
        transition: max-height $homepage-card-duration ease-in-out;
        &.active{
            max-height:10000px;
        }
        a{
            @include transition($nav-link-transition);
            &:hover{
                color:$premier!important;
            }
        }
    }
}