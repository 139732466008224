@import "./src/scss/abstracts/variables";

$portal-heading-font: $headings-font-family;
$portal-strip-heading-size: 35px;
$header-font-size: 75px;

.portal {
    > div:not(.portal-dots){
        position: relative;
        z-index: 1;
    }
    &-heading {
        @include rfs($portal-strip-heading-size, font-size);
        font-family: $headings-font-family;
        line-height: 1.25;
        @media (max-width: 450px){
            font-size: 20px;
        }
    }
    &-dots{
        //background: center / contain repeat-y url("images/portal/background-circles.png");
        // background-attachment: fixed;
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        z-index: 0;
    }

    &-banner {
        
        @include media-breakpoint-down(lg) {
            .vh-100{
                height: auto!important;
            }
        }
        &-container {
            @include rfs(7rem, padding-top);
        }
    
        h1 {
            // @include rfs($header-font-size, font-size);
            font-size: 75px;          
            @include media-breakpoint-down(lg){
                font-size: 50px;
            }            
            @include media-breakpoint-up(md){
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        img {
            @include media-breakpoint-down(sm) {
                height: 70%;
            }

            @include media-breakpoint-between(sm, md) {
                height: 90%;
            }

            @include media-breakpoint-between(md, lg) {
                height: 67%;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .tagline,.tagline-image, .tagline-dot-1,.tagline-dot-2, .tagline-dot-3{
            opacity: 0;
        }
        .tagline, .tagline-dot-1,.tagline-dot-2, .tagline-dot-3{
           animation: fadeIn 1s ease-out forwards;
        }
        .tagline{
            animation-delay:0.75s;
        }
        .tagline-dot-1{
            animation-delay: 1.5s;
         }
         .tagline-dot-2{
            animation-delay: 2.25s;
         }
         .tagline-dot-3{
            animation-delay: 3s;
         }
         .tagline-image{
            // opacity: 0;
            animation: fadeIn 3.5s ease-out forwards;
            animation-delay: 3s;
         }
        // .animated{
        //     animation: fadeIn 0.5s ease-out forwards;
        // }
        
    
    }

    &-strip{
        overflow: hidden;
       min-height: 95vh;
       @include media-breakpoint-down(lg) {
        min-height: auto;
       }
    //    iframe{
    //     opacity: 0;
    //     transition-property: opacity;
    //  transition-duration: .5s;
    //  transition-timing-function: cubic-bezier(0.25,1,0.5,1);  
    //    }
        .is-visible{
            .portal-mobile-overlap-left{
                --right:0%;
                animation: slideRight 0.66s ease-in-out forwards;
                //animation-delay: 750ms;
            }
            .portal-mobile-overlap-right{
                --left:0%;
                animation: slideLeft 0.66s ease-in-out forwards;
                //animation-delay: 750ms;
            }
            .portal-mobile-left{
                --right: -5%;
                animation: slideRight 0.66s ease-in-out forwards;
                //animation-delay: 750ms;
            }
            .portal-mobile-right{
                --left: -5%;
                animation: slideLeft 0.66s ease-in-out forwards;
               // animation-delay: 750ms;
            }
            
            @media (max-height: 800px){
                .portal-ipad-100{
                --bottom:-15%;
                animation: slideUp 0.66s ease-in-out forwards;
                }
            }
            
    }
    @include media-breakpoint-down(md) {
        p{
            text-align: center;
        }
    }
}

    &-highlight-text {
        background: #22384e!important;
        @include media-breakpoint-down(md) {
        text-align: center;
        }
       //background: center / cover no-repeat url("images/portal/Gradient-background-1.jpg");
       .container-xl{
           position: relative;
           z-index: 1;
           @media (max-width: 769px){
           .ratio {
                &:before{
                    display: none!important;
                }
                > *{
                    position: relative;
                }
            }

           }
       }
       overflow: hidden;
        li {
            @include rfs(55px, font-size);
            @include media-breakpoint-down(lg){
                @include rfs(44px, font-size);
            }
            @media (max-width: 450px){
                @include rfs(33px, font-size);
            }
            // @include media-breakpoint-down(sm){
            //     @include rfs(22px, font-size);
            // }
            // @media (max-width: 450px){
            //     @include rfs(16px, font-size);
            // }
            font-family: $portal-heading-font;
            color: $secondary;
            opacity: 0.3;
            display: inline;
            //margin-right:$spacer;
            margin-right: 5px;
            transition-property: opacity;
            transition-duration: .5s;
            transition-timing-function: cubic-bezier(0.25,1,0.5,1);
            line-height: 1.3;
        }
        ul{
            list-style: none;
            padding:0;
            margin:0;
        }
        
    }

    &-app {
        border-radius: 50rem;
        @include media-breakpoint-down(md){
            border-radius: 1rem;
        }

        overflow: auto;

        &-apple {
            background: center / cover no-repeat url("images/portal/apple.png");
            height: 3.125rem;
            aspect-ratio: 3;

            @include media-breakpoint-down(md) {
                height: 2.75rem;
            }
            @include media-breakpoint-down(sm) {
                height: 2.5rem;
            }
        }
        &-google {
            background: center / cover no-repeat url("images/portal/google-play.png");
            height: 3.125rem;
            aspect-ratio: 3.38;

            @include media-breakpoint-down(md) {
                height: 2.75rem;
            }
            @include media-breakpoint-down(sm) {
                height: 2.5rem;
            }
        }
    }
    &-ipad{
        position:relative;
        width: 100%;
        display: inline-block;
        &-100{
            width: 558px;
            @media (max-width: 1280px){
                width:512px;
            }
            @include media-breakpoint-down(lg) {
               //background-color: red;
               width:413px;
               bottom:auto!important;
               animation:  none!important;
            }
            @media (min-width:768px) and (max-width: 865px){
                width: 360px;
            }
            @include media-breakpoint-down(sm) {
                width: 272px;
            }
            @media (max-height: 800px){
            bottom:-100%;
            }
            
        }
        &-80{
            width: 80%;
            @media (max-width: 1280px){
                width:410px;
            }
            @include media-breakpoint-down(lg) {
                width: 330px;
            }
            @media (min-width:768px) and (max-width: 865px){
                width:288px;
            }
            @include media-breakpoint-down(md) {
                //width: 100%;
                width:410px;
            }
            @include media-breakpoint-down(sm) {
                width: 272px;
            }
            .ipad-container,.clip-ipad{
                height:650px;
                @media (max-width: 1280px){
                    height:538px;
                   // background:red;
                    
                }
                @include media-breakpoint-down(lg) {
                  // background-color: lime;
                   height:433px;
                   
                   
                }
                @media (min-width:768px) and (max-width: 865px){
                   height: 378px;
                   //background:pink;
                  
                  
                }
                @include media-breakpoint-down(md) {
                   
                    //background: purple;
                    height:538px;
                }
                @include media-breakpoint-down(sm) {
                    height: 358px;
                    //background-color: green;
                }
            }
            
            + .portal-mobile-40{
                position: absolute;
                right: 0;
                bottom: -6.5px;
            }
        }
    }
    &-mobile{
        position:relative;
        width: 100%;
        display: inline-block;
       // background:yellow;
        &-50{
            width: 50%;
            @media (max-width: 1280px){
                width:250px;
            }
            @include media-breakpoint-down(lg) {
                //width:200px;
                width:148px;
            }
            // @media (min-width:768px) and (max-width: 865px){
            //     width:150px;
            // }
            // @include media-breakpoint-down(sm) {
            //     width:150px;
            // }
        }
        &-left{
            right:0%;
        }
        &-right{
            left:0%;
            z-index: 10;
            position: relative;
        }
        &-horizontal{
            position: absolute;
            left: 43%;
            
            @media (max-width: 1400px){
                left:33%;
            }
            @media (max-width: 1280px){
                left: 43%;
            }
            bottom: -9px;
            width: 480px;
            @media (max-width: 1280px){
                width:380px;
               // background:red;
            }
            @include media-breakpoint-down(lg) {
                width:280px;
               // background:lime;
            }
            // @media (min-width:768px) and (max-width: 865px){
            //     width:180px;
            //     background:pink;
            // }
            @include media-breakpoint-down(sm) {
                // width:180px;
                // background:pink;
                display: none!important;
            }
            overflow: hidden;
            z-index: 10;
            .iphone-frame{
                background: top / contain no-repeat url("images/portal/iphone-frame-horizontal.svg");
            }
            // iframe{
            //     padding:13px;
            // }
            .iphone-container{
                height:248px;
                @media (max-width: 1280px){
                    height:200px;
                }
                @include media-breakpoint-down(lg) {
                    height:148px;
                }
                @media (min-width:768px) and (max-width: 865px){
                   // height:150px;
                }
                @include media-breakpoint-down(sm) {
                    //height:150px;
                }
            }
            .clip-iphone-h{
                width: 95%;
                left: 2.5%;
                top:2.5%;
            }
        }
        // &-33{
        //     //width: 33%;
        //     width:36%;
        //     // @media (max-width: 1280px){
        //     //     width:205px;
        //     // }
        //     // @include media-breakpoint-down(lg) {
        //     //     width:155px;
        //     // }
        //     .iphone-container{
        //         height:480px;
        //         background:purple;
        //         // @media (max-width: 1280px){
        //         //     height:390px;
        //         //     //background:red;
        //         // }
        //         // @include media-breakpoint-down(lg) {
        //         //     height: 290px;
        //         //     //background:lime;
        //         // }
        //     }
        //     // .clip-iphone{
        //     //     top: 3%;
        //     //     height: 92%;
        //     //     @include media-breakpoint-down(lg) {
        //     //         top: 2.5%;
        //     //         height: 95%;
        //     //     }
        //     // }
        // }
        &-40{
            width: 40%;
            z-index: 10;
            @media (max-width: 1280px){
                width:200px;
            }
            @include media-breakpoint-down(lg) {
                width:148px;
            }
            @media (min-width:768px) and (max-width: 865px){
               // width:150px;
            }
            @include media-breakpoint-down(sm) {
                //width:150px;
            }
            .iphone-container{
                height:480px;
               // background: purple;
                @media (max-width: 1280px){
                    height:380px;
                   // background:red;
                }
                @include media-breakpoint-down(lg) {
                    height:280px;
                   // background:lime;
                }
                // @media (min-width:768px) and (max-width: 865px){
                //     height:180px;
                //     background:pink;
                // }
                // @include media-breakpoint-down(sm) {
                //     height:180px;
                //     background:pink;
                // }
                
            }
            // iframe{
            //     padding:13px;
            // }
        }
        &-overlap-left,&-overlap{
            right:10%;
            z-index: 10;
            position: relative;
        }
        &-overlap-right{
            left:10%;
            z-index: 10;
            position: relative;
        }
        &-overlap-right, &-overlap-left{
            @include media-breakpoint-down(lg) {
                width:138px;
            }
        }
    }
}

.opacity-1 {
    opacity: 1 !important;
}

#phone_mask{
    height: 0;
}


.iphone{
    &-container{
        height:610px;
        @media (max-width: 1280px){
            height:475px;
           // background-color: red;
        }
        @include media-breakpoint-down(lg) {
           // height:400px;
           height:280px;
           // background: lime;
        }
        // @media (min-width:768px) and (max-width: 865px){
        //     height:316px;
        //    // background: pink;
        // }
        // @include media-breakpoint-down(sm) {
        //     height:316px
        // }
    // background:blue;
        overflow: hidden;
        position: relative;
        //background:blue;
        @include media-breakpoint-down(lg) {
         //   background:red;
        }
        @include media-breakpoint-down(md) {
           // background:yellow;
        }
        @include media-breakpoint-down(sm) {
           // background:green;
        }

    }
    &-frame {
        height: 100%;
        background: top / contain no-repeat url("images/portal/iphone-frame.svg");
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: 10;
     // opacity: .5;
    }
}

.ipad{
    &-container{
       height:732px;
       @media (max-width: 1280px){
            height:665px;
        }
        @include media-breakpoint-down(lg) {
           height:540px;
        }
        @media (min-width:768px) and (max-width: 865px){
            height: 475px;
        }
        @include media-breakpoint-down(sm) {
            height:355px;
        }
        
    // background:blue;
        overflow: hidden;
        position: relative;
        
    }
    &-frame {
        height: 100%;
        background: top / 101% no-repeat url("images/portal/ipad-frame.svg");
        position:absolute;
        top:2px;
        left: 0;
        width: 100%;
        z-index: 10;
        //opacity: .5;
    }
}

.clip{
    &-iphone,&-ipad,&-iphone-h{
        position: absolute;
        z-index: 0;
        left: 0;
        width: 100%;
        height: 100%;
       // background:yellow;

        
    }
    &-iphone{
        -webkit-clip-path: url("#clipMobile");
        clip-path: url("#clipMobile");   
        object-fit: cover;         
        top:2%;
        left: 5%;
        width: 90%;
        height: 95%;
    }
    &-iphone-h{
        -webkit-clip-path: url("#clipMobileH");
        clip-path: url("#clipMobileH");   
        object-fit: cover; 
        //background:yellow;
        top:5%;
        //left: 4%;
       // width: 92%;
        height: 90%;
    }
    &-ipad{
         -webkit-clip-path: url("#clipIpad");
         clip-path: url("#clipIpad");   
         object-fit: cover; 
         //background:yellow;
        //  width: 90%;
        //  left:5%;
        //  height: 88%;
         top:4%;
     }
}

  @keyframes fadeInUp{
    to{
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  @keyframes fadeIn{
    to{
      opacity: 1;
    }
  }

  @keyframes slideRight{
    to{
      right:var(--right);
    }
  }

  @keyframes slideLeft{
    to{
      left:var(--left);
    }
  }
  @keyframes slideUp{
    to{
      bottom:var(--bottom);
    }
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50%,100% {
      opacity: 1;
    }
  }

  .highlight-bg{
      position: absolute;
      width:100%;
      //height: 100%;
      bottom:0;
      //left: -$grid-gutter-width/2;
      left:-0.8%;
      z-index: 0;
      @media (max-width: 769px){
        .embed-responsive-item{
            position: relative;
            //top: 50%!important;
            left: 50%!important;
            transform: translate(-50%);
            width: 200%!important;
            height: 200%!important;
            top:-80%!important;
        }
        }
     
  }
  