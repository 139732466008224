@import "./src/scss/abstracts/variables";

.blog-post-tile {
    &:hover {
        color: $asset;
    }
}

.insights-back{
    &:hover {
        color: $primary!important;
    }
}