@import "./src/scss/abstracts/variables";

.btn-hover {
    @include transition($nav-link-transition);
    background-color: $primary;
    color: $secondary;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    &:hover,&:active,&:focus {
        background-color: $tertiary;
        color: $primary;
    }
}

.dark-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $primary;
    color: $secondary;
    &:hover,&:active,&:focus {
        background-color: $secondary;
        color: $primary;
    }
    &-blog {
        &:hover,&:active,&:focus {
            background-color: $primary !important;
            color: $secondary!important;
        }
    }
    &.premier {
        &:hover,&:active,&:focus {
            background-color: $premier !important;
            color: $secondary!important;
        }
    }
    &.private {
        &:hover,&:active,&:focus {
            background-color: $private !important;
            color: $secondary!important;
        }
    }
    &.asset {
        &:hover,&:active,&:focus {
            background-color: $asset !important;
            color: $secondary!important;
        }
    }
    &.gold {
        &:hover,&:active,&:focus {
            background-color: $tertiary !important;
            color: $secondary!important;
        }
    }
}

.premier-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $premier;
    color: $secondary;
    &:hover,&:active,&:focus {
        background-color: $secondary;
        color: $premier !important;
    }
    &-blog {
        &:hover,&:active,&:focus {
            background-color: $premier !important;
        }
    }
}

.private-btn {
    @include transition($nav-link-transition);
    cursor: pointer;
    background-color: $private;
    color: $secondary !important;
    &:hover,&:active,&:focus {
        background-color: $secondary;
        color: $private !important;
    }
}

.asset-btn {
    @include transition($nav-link-transition);
    cursor: pointer;
    background-color: $asset;
    color: $secondary !important;
    &:hover,&:active,&:focus {
        background-color: $secondary;
        color: $asset !important;
    }
}

.gold-btn {
    @include transition($nav-link-transition);
    cursor: pointer;
    background-color: $tertiary;
    color: $secondary !important;
    &:hover,&:active,&:focus {
        background-color: $secondary;
        color: $tertiary !important;
    }
}


.dust-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $secondary;
    color: $primary;
    &:hover,&:active,&:focus {
        background-color: $premier;;
        color: $primary;
    }
}