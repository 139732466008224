@import "./src/scss/abstracts/variables";

.modal-menu-open {
    #navbar-brand {
        width: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out, width 0s linear 0.3s;
    }
    .navbar-toggler {
        opacity: 0;
        transition: opacity 0.3s ease-in-out, width 0s linear 0.3s;
    }
    @include media-breakpoint-down(lg){
        #mobile-header {
            display: flex;
        }   
    }
    @include media-breakpoint-up(lg){
        #modal-close {
            width: $close-size;
            opacity: 1;
            transition: width 0s linear 0.3s, opacity 0.3s ease-in-out 0.3s;
        }
    }
    .nav-link.active {
        color: $navbar-dark-color!important; // override active state when menu is open
        &.navbar-login {
            ::before {
                background: $navbar-dark-color!important; 
            }
        }
        &:hover {
            color: $navbar-dark-hover-color!important;
            &.navbar-login {
                ::before {
                    background: $navbar-dark-hover-color!important; 
                }
            }
        }
        &.modal-active {
            color: $navbar-dark-active-color!important;
        }
    }
    .modal-active {
        color: $navbar-dark-active-color!important;
    }
    .btn.rounded-pill.modal-active {
        border-color: $premier;
        &::before {
            background-color: $premier;
        }
    }
}

// override standard bootstrap class
.btn-close{
    display: block;
    height: $close-size;
    width: $close-size;
    background: $primary;
    -webkit-mask-image: url("images/close.svg");
    mask-image: url("images/close.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top;
    mask-position: top;
    -webkit-mask-size: contain;
    mask-size: contain;
    padding: 0!important;
    margin: 0!important;
    @include transition($nav-link-transition);
    &:hover {
        background: $tertiary;
    }
}

// from state
#modal-close {
    width: 0;
    text-align: right;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.1s ease-in-out, width 0.2s linear 0.1s;
   // transition: 
}