@import "./src/scss/abstracts/variables";

table {
    @extend .table;
    &.tablepress {
        width: 100%!important;
        border-collapse: collapse;
        border-style: hidden;
        //table-layout: fixed;
        td, th {
            min-width: 4rem;
            border: 2px solid $primary;
            white-space: nowrap!important;
        }
        &.tablepress-fixed{
            table-layout: fixed;
            td, th {
                white-space: initial!important;
            }
            min-width: 818px;
        }
        &.tablepress-sticky-column{
            .column-1 {
                position: sticky;
                left: 0;
                background: $tablehead!important;
                z-index: 1;
                @include media-breakpoint-down(sm){
                    width: 100px!important;
                }
               
            }
        } 
        &.tablepress-double-merged-header{
            thead{
                .column-1{
                    background: $secondary!important;
                }
            }
            thead + tbody > tr:first-child{
                background-color: $tablehead!important;
                > td{
                    min-width: 4rem;
                    border: 2px solid $primary;
                    white-space: nowrap!important;
                    font-weight: $font-weight-bold;
                }
            }
        }
        &.tablepress-text-center{
            tbody tr td:not(.column-1){
                text-align: center!important;
            }
        }

        thead, tfoot {
           border-bottom: 1.5px solid $primary;
            tr {
                background-color: $tablehead!important;
            }
        }

        &:not(.tg) {
            tr {
                td:not(:first-child), th:not(:first-child) {
                    text-align: center;
                }
            }
        }
        tr:last-child{
            td{
                border-bottom: none!important;
            }
        }
    }
}

.tablepress-table-description {
    @extend .small;
}

.dataTables_filter {
    display: none;
}

.fixedHeader-floating,.fixedHeader-locked {
    background-color: $secondary !important;
}

.fixedHeader-floating {
    @include media-breakpoint-down(lg) { 
        display: none;
    }
}

.dataTables_wrapper.no-footer .dataTables_scrollBody{
    border: none;
}

.tablepress tfoot th, .tablepress thead th, .tablepress .even td, .tablepress .odd td{
    background-color: transparent!important;
}

.tablepress-scrollable{
	overflow-x: auto;
    width: 100%;
}

// table icons

.aau-arrow {
    position: relative;
    
    &-right:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right.svg');
        width: 78px;
        height: 22px;
        left: -85px;
        background-size: 78px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: -70px;
            top: 1px;
            background-size: 62px;
        }
        @include media-breakpoint-down(sm) {
            left: -58px;
            top: 2px;
            background-size: 55px;
        }
    }

    &-right-longer:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right-longer.svg');
        width: 150px;
        height: 22px;
        left: -140px;
        background-size: 140px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: -153px;
            background-size: 146px;
        }
        @include media-breakpoint-down(sm) {
            left: -134px;
            top: 0px;
            background-size: 130px;
            width: 150px;
        }
    }
    &-right-longer-3col:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right-longer-3col.svg');
        width: 225px;
        height: 24px;
        right: 5px;
        background-size: 100%;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            right: -1px;
            width: 259px;
            background-size: 255px;
            height: 30px;
        }
        @include media-breakpoint-down(sm) {
            right: 5px;
            top: -2px;
            background-size: 230px;
            width: 230px;
        }
    }


    &-left:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left.svg');
        width: 78px;
        height: 22px;
        left: 12px;
        background-size: 78px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: 0px;
            background-size: 62px;
        }
        @include media-breakpoint-down(sm) {
            left: 3px;
            top: 1px;
            background-size: 65px;
        }
    }

    &-left-longer:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left-longer.svg');
        width: 140px;
        height: 24px;
        left: 12px;
        background-size: 140px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: -11%;
            background-size: 82%;
            width: 187px;
        }
        @include media-breakpoint-down(md) {
            left: 7px;
            top: -11%;
            background-size: 100%;
            width: 140px;
        }
    }

    &-left-longer-3col:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left-longer-3col.svg');
        width: 225px;
        height: 24px;
        left: 8px;
        background-size: 225px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: -20%;
            background-size: 100%;
            width: 260px;
            height: 30px;
        }
        @include media-breakpoint-down(md) {
            left: 7px;
            top: -11%;
            width: 225px;
        }
    }
}
