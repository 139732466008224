@import "./src/scss/abstracts/variables";


.header {
    .nav-item {
        .nav-link {
            font-size: 1.125rem !important;
            &.contact-button {
                border: 1.5px solid white !important;
                @include transition($nav-link-transition);
                &:hover,&.text-tertiary {
                    border: 1.5px solid $tertiary !important;
                }
                
            }

            color: $secondary;
            text-decoration: none;
            @include transition($nav-link-transition);
            &:hover {
                color: $tertiary;
                .nav-item.border {
                    border-color: $tertiary !important;
                }
            }
        }
    }
    .menu-bars {
        cursor: pointer;
    }
    &-condensed{
        @include media-breakpoint-down(xl){ 
        .navbar-nav .nav-item .nav-link, .nav-item a{
            font-size: $font-size-base!important;
        };
    }
    }
}

.navbar-nav {
    .nav-item {
        .nav-link { 
            font-size: 1.125rem !important;
            position: relative;
            &-wp, &.submenu-active{
                &:after{
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 12px solid rgba(0,0,0,0);
                    border-right: 12px solid rgba(0,0,0,0);
                    border-bottom: 12px solid $midblue;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    bottom: -$spacer*2.45;
                    opacity: 0;
                }
                &.text-tertiary{
                    &:after{
                        opacity: 1;
                    }
                }
            }
            
        }
        ul li .nav-link{
                padding-left: $spacer*2;
                font-family: $font-family-base;
                font-size: $font-size-base!important;
        }
    }
}

.offcanvas-header {
    height: $offcanvas-header;
    .fa-xmark {
        cursor: pointer;
    }
}

.partner-logo{
    width: 190px;
    svg{
        fill:$secondary;
        @include transition(fill .15s ease-in-out);
    }
    &:hover {
        svg{
            fill:$tertiary;
        }
    }
}