@import "./src/scss/abstracts/variables";

.banner + article > main > .partner-text-image:first-of-type  {
    @include media-breakpoint-up(lg){
        margin-top: $spacer*3.5!important;
        > .row > div + div {
            margin-top: -$spacer*10.5;
        }
    }
    }

.hero {

    background-color: $primary;
    @include media-breakpoint-up(lg){ 
        height: 600px;
        background-color: transparent;
        &-image {
            img {
                object-fit: cover;
            }
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            overflow: hidden;
            z-index: -1;
        }
        &-fade {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right, rgba(24, 38, 58, 1) 0%, rgba(24, 38, 58, 0) 100%);
            pointer-events: none;
        }
    }
    @include media-breakpoint-down(lg){ 
        img{
            position: relative!important;
        }
        .min-vh-100{
            min-height: auto!important;
        }
    }
}
