@import "./src/scss/abstracts/variables";
.team-profile {
    position:relative;
    // margin: $spacer ($spacer * 2.5);
    &-inner {
        padding-top:100%; // should make it square
    }
    &-bg {
        circle {
            fill: $tertiary;
        }
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &-image {
        width:100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: bottom center;
        mask-position: bottom center;
        -webkit-mask-image: url("images/headshot-mask.svg");
        mask-image: url("images/headshot-mask.svg");
        //transform: translateY(20%);
    }
}

@each $name, $colour in $brand-colors {
    .team-profile-#{$name} {
        .team-profile-bg {
            circle {
                fill: #{$colour};
            }
        }
    }
}