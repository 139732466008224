@import "./src/scss/abstracts/variables";

.w-30{
    width: 30px;
}

.mw-40{
    max-width: 49%;
}

.mt-header-height {
    margin-top: $mt-header-height;
    &.mt-subheader-height{
        margin-top: ($mt-header-height + $mt-subheader-height);
        @include media-breakpoint-down(lg){ 
            margin-top: $mt-header-height;
        }
    }
}

.accordion-button {
    font-family: $headings-font-family;
    &:not(.collapsed){
        i{
            transform: rotate(-180deg);
        }
        
    }
    i{
        transition: transform 0.3s ease-in-out, width 0s linear 0.3s;
    }
    &:after{
        display: none!important;
    }
}


.contact-icon {
    i{
        font-size: 70px;
    }
    + div{
        @include media-breakpoint-down(md){ 
        text-align: center;
        }
    }
}

.arrow-link{
    @at-root {
        a#{&}{
            display: flex;
            align-items: center;
            justify-content: space-between;  
        }
    }
    i{
        transition: transform 0.3s ease-in-out, width 0s linear 0.3s;
    }
    &:hover {
        i{
            transform: translateX(0.15rem);
        }
    }
    &-down{
        &:hover {
            i{
                transform: translateY(0.15rem) translateX(0);
            }
        }
        &.active {
            i {
                transform: rotate(-180deg);
            }
            &:hover{
                i {
                    transform: translateY(-0.15rem) rotate(-180deg);
                }
            }
        }
    }
    &-left{
        &:hover {
            i{
                transform: translateX(-0.15rem);
            }
        }
    }
}

.asset-content{
    a{
        &:hover{
            color: $asset;
        }
    }
}


img{
    transition: 600ms opacity ease-in-out;
}

blockquote {
	font-family: $headings-font-family;
	font-size: $h3-font-size;
	line-height: $headings-line-height;
	margin-top: $spacer * 2;
	margin-bottom: $spacer * 2;
}

//theme colours
.private{
    .blog ul li::before, .blog ol li::before,  .blog:not(.wealth-index-text) ul li::before, .blog:not(.wealth-index-text) ol li::before, blockquote {
        color: $private!important;
}
    a{
        &:hover{
            color: $private!important;
        }
    }
    .eat-citation{
        color: $private!important;
        &:hover{
            color: $private!important;
        }
    }
}
.premier{
    blockquote{
        color:$midblue!important;
    }
    .blog ul li::before, .blog ol li::before,  .blog:not(.wealth-index-text) ul li::before, .blog:not(.wealth-index-text) ol li::before{
            color: $premier!important;
    }
   
    a{
        &:hover{
            color: $premier!important;
        }
    }
    .eat-citation{
        color: $premier!important;
        &:hover{
            color: $premier!important;
        }
    }
}
.asset{
    .blog ul li::before, .blog ol li::before,  .blog:not(.wealth-index-text) ul li::before, .blog:not(.wealth-index-text) ol li::before, blockquote{
        color: $asset!important;
}
    a{
        &:hover{
            color: $asset!important;
        }
    }
    .eat-citation{
        color: $asset!important;
        &:hover{
            color: $asset!important;
        }
    }
}

//tooltip

.tooltip-inner{
	@extend .shadow;
	border-radius: $tooltip-border-radius;
	position: relative;
	z-index: 1;
}
.tooltip-arrow{
	border-right-color: $tooltip-bg !important;
}
  
  .tooltip-arrow::after {
	content: "";
	position: absolute;
	left: 50%;
	z-index: -1;
	border: $spacer*0.75 solid $tooltip-bg ;
	transform-origin: 0 0;
	transform: rotate(45deg);
	@extend .shadow;
  }

  .tooltip-premier{
    &:hover{
        color:$premier;
    }
  }
  .tooltip-private{
    &:hover{
        color:$private;
    }
  }
  .tooltip-asset{
    &:hover{
        color:$asset;
    }
  }