
// Asset Allocation

.anchor {
    top: -165px;
    display: block;
    position: relative;
    // visibility: hidden;
    @include media-breakpoint-down(lg) {
        top: -518px;
    }
}


$aa-title-height: 110px;

.asset-allocation {
    // &-title {
    //     height: $aa-title-height;
    //     img {
    //         object-fit: contain;
    //         height: 100%;
    //     }
        
    //     h2 {
    //         font-size: 40px !important;
    //         @include media-breakpoint-down(lg) {
    //             font-size: 35px !important;
    //         }
    //         @include media-breakpoint-down(md) {
    //             font-size: 30px !important;
    //         }
    //         @include media-breakpoint-down(sm) {
    //             font-size: 25px !important;
    //         }
    //     }
    //     .background {
    //         height: $aa-title-height;
    //         left: 0;
    //         width: 100vw;
    //         z-index: -1000;
    //     }
    // }


    &-question::before {
        content: 'Q:';
    }

    &-answer::before  {
        content: 'A:';
    }

    &-question,
    &-answer {
        padding-left:25px;
        &::before {
            font-weight: bold;
            font-size: 18px;
            position: absolute;
            left: 0;
        }
    }

    &-author {
        a:hover {
            color: $private;
        }
    }

    &-table {
        margin: 1rem 0;
        table {
            width: 100%;
            border-collapse: collapse;
            border-style: hidden;
            td, th {
                border: 2px solid transparentize($primary, 0);
                white-space: nowrap;
            }
        
            thead, tfoot {
                border-bottom: 3px solid transparentize($primary, 0);
    
                tr {
                    @extend .bg-tint;
                    @extend .bg-opacity-20;
                }
            }
    
            &:not(.tg) {
                tr {
                    td:not(:first-child), th:not(:first-child) {
                        text-align: center;
                    }
                }
            }
            &-sticky-column{
                .column-1 {
                    position: sticky;
                    left: 0;
                    background: $tablehead!important;
                    z-index: 1;
                    @include media-breakpoint-down(sm){
                        width: 100px!important;
                    }
                   
                }
            } 
        }
    }
}

.q_and_a_author {
    // position: absolute;
    // left: -10rem;
    .team-profile {
        width: 8rem;
    }
    @include media-breakpoint-down(lg) {
        position: relative;
        left: initial;
    }
}


//wealth index bits
$wi-height: 140px;

.wealth-index {
    &-title {
        border-radius: 200px 0 0 200px!important;
        &:first-of-type {
            margin-top: 24px !important;
            @include media-breakpoint-down(lg){
                margin-top: 0 !important;
            }
        }

        + .wealth-index-sub-title {
            margin-top: 30px !important;
        }

        margin-bottom: 30px !important;
        height: $wi-height;
        img {
            object-fit: contain;
            height: 100%;
        }
        .background {
            height: $wi-height;
            right: 0;
            width: 50vw;
            z-index: -1000;
            @include media-breakpoint-down(lg){
                left: 0;
                width: 100vw;
            }
        }

        
           
    }
    &-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 200px;
        z-index: 10;
        @media screen and (min-width: 1070px) and (max-height: 800px) {
            > h5{
                display: none!important;
            }
        }
        
        nav#{&}{
            //overflow-y: scroll;
            height: auto!important;
        }
        @include media-breakpoint-down(lg){
            top: $mobile-sticky-top !important;
        }
        // &.col-lg-3{
        //     @include media-breakpoint-down(lg){
        //         top: 138px !important;
        //         background:yellow!important;
        //         padding:0;
        //         //width:101%;
        //         margin-left:-$grid-gutter-width/2;
        //     }
        // }
            
        &-mobile {
            border-bottom-width: 2px !important;
            .accordion-button {
                display: flex;
                align-items: center;
            }
            #menuItems .nav-item:last-child{
                border-bottom: none!important;
            }
        }
        .nav-link {
            font-family: $headings-font-family;
            padding-top: 16px;
            padding-bottom: 16px;
            @media screen and (min-width: 1070px) and (max-height: 800px) {
                padding-top: 12px;
                padding-bottom: 12px;
                font-size: $font-size-sm;
              }
            &:hover {
                @include media-breakpoint-down(lg){
                    color: $secondary !important;
                }
            }
            &.active {
                color: $premier !important;
            }
        }
    }
    &-anchor {
        top: -200px !important;
        display: block;
        position: relative;
        @include media-breakpoint-down(lg){
            top: -630px !important;
        }
    }
}