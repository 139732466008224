@import "./src/scss/abstracts/variables";

.eat-citation{
    color:$asset;
    text-decoration: none;
    &:hover{
        color:$asset;
    }
}

$blog-h2-size: 1.563rem;
$blog-h3-size: 1.375rem;
$blog-h4-size: 1.125rem;

.blog {
    &:not(.wealth-index-text){
        h2, h3, h4 {
            padding-top: 1.5rem;
            line-height: 1.4;
        }

        ul, ol {
            list-style: none; /* Remove default bullets */
            padding-left: 1.5rem;
          }
          
          ol{
            counter-reset: li;
            padding-left: 1.5rem;
          }
          li {counter-increment: li;
            margin-bottom: 0.25rem;}
          ul li::before,  ol li::before {
            color: $asset; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
          

          ol li:nth-child(n+10)::before{
            width: 1.5em; /* Also needed for space (tweak if needed) */
            margin-left: -1.5em; /* Also needed for space (tweak if needed) */
          }

          ul li::before{
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */

          }
          ol li::before{
            content: counter(li);

          }

          h3{
            margin-bottom: 1.5rem;
          }

        // h4, .h4 {
        //     font-size: $blog-h4-size;
        // }
        // h2 {
        //     font-size: $blog-h2-size;
        // }
        // h3 {
        //     font-size: $blog-h3-size;
        // }
    }

    &.bullet {
        padding-left:30px;
        h4, .h4 {
            position: relative;
            margin-bottom: 0 !important;
            &:before {
                content: '';
                background: url('images/bullet.png');
                width: 22px;
                height: 22px;
                position: absolute;
                left: -30px;
                top: 25px;
                background-size: 25px;
                background-repeat: no-repeat;
            }
            @include media-breakpoint-down(lg) {
                &:before {
                    left: -30px;
                    top: 27px;
                    background-size: 22px;
                }
            }
        }
    }

    &-author {
        &-image {
            //width: 11%;
            width: 90px;
            @include media-breakpoint-down(md) {
                //width: 17%;
                width:70px;

            }
        }
    }

    &-text {
        .quote {
            p {
                @extend .h4;
            }
        }

        .disclaimer {
            p {
                @extend .small;
            }
        }
    }

    .guide-image {
        max-width: 127px;
        object-fit: contain;
    }
}

.author-body{
    a{
        @include media-breakpoint-down(sm) {
            display: block!important;
        }
    }
}

.header-sticky {
    @include transition($nav-link-transition);
    &-hide {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
    }
    &-show {
        max-height: auto;
        opacity: 1;
        visibility: visible;
    }
    &-aau {
        position: -webkit-sticky;
        position: sticky;
        top: 104px;
        z-index: 1;
        &-mobile {
            top: 90px !important;
            .accordion-button {
                display: flex;
                align-items: center;
            }
        }
        p {
            margin-bottom: 0;
        }
        .nav-link {
            border-bottom: 1px solid transparent;
            &:hover, &:active {
                color: $secondary !important;
                border-bottom: 2px solid #fff;
            }
            &.active {
                border-bottom: 2px solid #fff;
            }
        }
    }
}

.post-list {
    // img {
    //     // @include transition($nav-link-transition);
    //     transition: transform 500ms ease;
    //     cursor: pointer;
    //     transform: scale(1.01);
    //     &:hover {
    //         transform: scale(1.03);
    //         .post-list-title {
    //             color: $premier !important;
    //         }
    //     }
    // }

    &-title {
        @include transition($nav-link-transition);
    }
}

.blog-post-tile{
        img{
            transition: transform 500ms ease;
            cursor: pointer;
            transform: scale(1.01);
        }
        &:hover {
            img{
                transform: scale(1.03);
            }
        }
}


// .container-xl .container-xl{
//     padding-left: 0!important;
//     padding-right: 0!important;
// }
