@use "sass:math";

$enable-negative-margins: true;


$primary: #18263a;
$secondary: #fff5e6;
$tertiary: #EC9F22;
$midblue: #22384F;
$white: #ffff;
$tint: #325a7d;

$premier: #f0645a;
$private: #49796b;
$asset: #3879b2;
$midasset: #5b91c7;
$lorica: #b04f7d;

$tablehead: #D6D6D2;
$red:#dc3545;




// Brand colour map
$brand-colors: (
  premier: $premier,
  private: $private,
  asset: $asset,
  lorica: $lorica,
  dust: $secondary,
  navy: $primary,
  gold: $tertiary,
  midblue: $midblue, 
  midasset: $midasset,
  tint:$tint
);

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  light: $secondary,
  dark: $primary,
  danger: $red
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "10x7": calc(7 / 10 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

$body-color: $midblue;
$body-bg: $secondary;

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop(
  map-merge($brand-colors, $theme-colors), to-rgb, "$value"
) !default;

// scss-docs-start utilities-colors
// $utilities-colors: map-merge(
//   $theme-colors-rgb,
//   (
//     "white": to-rgb($white),
//     "body":  to-rgb($body-color)
//   )
// ) !default;
// scss-docs-end utilities-colors

// scss-docs-start utilities-text-colors
// $utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text") !default;
// scss-docs-end utilities-text-colors

// scss-docs-start utilities-bg-colors
// $utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg") !default;
// scss-docs-end utilities-bg-colors



$font-family-sans-serif: 'Roboto', sans-serif;

@font-face {
  font-family: 'TiemposHeadlineWeb';
  src: url('https://dicx5b59rkqbf.cloudfront.net/assets/TiemposHeadlineWeb-Medium.woff2') format('woff2');
}

$headings-letter-spacing: -1%;
$font-size-base: 1rem;

$h1-font-size:      $font-size-base * 3.125 !default; 
$h2-font-size:      $font-size-base * 1.875 !default; 
$h3-font-size:      $font-size-base * 1.563 !default; // 25px
$h4-font-size:      $font-size-base * 1.375 !default; // 22px
$h5-font-size:      $font-size-base * 1.25 !default;  // 20px
$h6-font-size:      $font-size-base * 1.125 !default; // 18px

$font-sizes: (
  1: $font-size-base * 1.563, 
  2: $font-size-base * 1.125, 
  3: $font-size-base,
  4: $font-size-base * 0.922,
);


$spacer: 1rem;
$spacers: (
    0:  0,
    1:  $spacer * .25,
    2:  $spacer * .5,
    3:  $spacer,
    4:  $spacer * 1.5,
    5:  $spacer * 3,
    6:  $spacer * 2.5,
    7:  $spacer * 3.75,  // 60 px
    8:  $spacer * 5,     // 80 px
    9:  $spacer * 6.25,  // 100 px
    10: $spacer * 7.5,   // 120 px
    11: $spacer * 8.5,   // 136 px
    12: $spacer * 9.5,   // 152 px
);


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1085px,
  xl: 1288px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1038px,
  xl: 1288px,
);


$headings-font-family: 'TiemposHeadlineWeb';
$headings-font-weight:  400;
$navbar-dark-color:           $secondary !important;
$navbar-dark-hover-color:     $tertiary;
$navbar-dark-active-color:    $tertiary;
$navbar-dark-brand-color:     $secondary;
$navbar-padding-y:            $spacer * 1.5;
$navbar-brand-padding-y:      0;
$navbar-height: 6.875rem;
$link-hover-color: $tertiary;

$mt-header-height: 5.4rem;
$mt-subheader-height: 4.6rem;
$social-size: 2rem;
$close-size: 1.25rem;
$offcanvas-header: 3.25rem;
$mobile-sticky-top: 87px;

$container-padding-x: 1.5rem;

$arrow-size: 1rem;
// // $social-size: 2rem;
// // $close-size: 1.25rem;
// // $logo-width: 10rem;
$back-size: 1.5rem;
// // $logo-height: $logo-width * 0.26;



// // $nav-link-translate-transition:  transform .15s ease-in-out;

// // $h1-font-size:                $font-size-base * 3.125 !default; // 50px
// // $h2-font-size:                $font-size-base * 1.875 !default; // 30px
// // $h3-font-size:                $font-size-base * 1.563 !default; // 25px
// // $h4-font-size:                $font-size-base * 1.375 !default; // 22px
// // $h5-font-size:                $font-size-base * 1.25 !default;  // 20px
// // $h6-font-size:                $font-size-base * 1.125 !default; // 18px
// // scss-docs-end font-variables

// // scss-docs-start font-sizes
// // $font-sizes: (
// //   1: $h1-font-size,
// //   2: $h2-font-size,
// //   3: $h3-font-size,
// //   4: $h4-font-size,
// //   5: 0.875rem,
// //   6: $h6-font-size
// // ) !default;




// // $enable-negative-margins: true;

// // $grid-breakpoints: (
// //   xs: 0,
// //   sm: 576px,
// //   md: 768px,
// //   lg: 1070px,
// //   xl: 1288px
// // );

// // $container-max-widths: (
// //   sm: 540px,
// //   md: 720px,
// //   lg: 1038px,
// //   xl: 1288px,
// // );


// // $navbar-nav-link-padding-x: 0;

// // $accordion-border-color: transparent;
// // $accordion-padding-x: 0;

// // $accordion-button-focus-box-shadow: none;

// // $btn-close-opacity: 1;
// // $btn-close-hover-opacity: 1;
// // $btn-close-focus-opacity: 1;

// // @import "bootstrap/scss/variables";



// // $container-padding-x: $grid-gutter-width;
// // $navbar-brand-margin-end: 0;
// // $navbar-toggler-padding-y: 0;

$header-height: 6.5rem;
$header-height-mobile: 5.5rem;

// // $border-radius:12px;
// these are all the defaults - needed for modal
$border-width:                1px;
$border-radius:               .375rem ;
$border-radius-sm:            .25rem ;
$border-radius-lg:            .5rem ;
$border-radius-xl:            1rem ;
$border-radius-2xl:           2rem ;
$border-radius-pill:          50rem ;

/* forms */

$input-bg: $secondary;
$input-border-width: $border-width;
$input-border-color: $primary;
$input-focus-bg: $secondary;
$input-color: $primary;
$input-focus-color: $input-color;
$input-focus-border-color:$tertiary;
$input-btn-focus-blur: 0;
$input-btn-focus-width: .25rem;
$input-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width tint-color($tertiary, 50%);
$form-select-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width tint-color($tertiary, 50%);


$form-select-color: $primary;
$form-select-bg: $input-bg;
$form-select-border-width: $input-border-width;


$input-placeholder-color: tint-color($primary, 50%);

// Modals

$modal-inner-padding: $spacer*1.5;
$modal-content-color:$primary;
$modal-content-bg: $secondary;
// $modal-content-border-color:        var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-radius:       $border-radius-lg;
$modal-content-border-width:        $border-width;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);

$modal-backdrop-bg:                 $primary;

$modal-header-border-color:         transparent;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-header-padding-y:            $modal-inner-padding !default;
// $modal-header-padding-x:            $modal-inner-padding !default;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-footer-bg:                   null !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-footer-border-width:         $modal-header-border-width !default;


// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-base;
$tooltip-max-width:                 400px; //200px default
$tooltip-color:                     $body-color;
$tooltip-bg:                        $body-bg;
$tooltip-border-radius:             $border-radius;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 $spacer*0.75;
$tooltip-padding-x:                 $spacer*0.75;

$tooltip-arrow-width:               $spacer*1.75;
$tooltip-arrow-height:              $spacer*0.75;
$tooltip-arrow-color:               $tooltip-bg;