@import "./src/scss/abstracts/variables";
// automatically add the lines dividing li on text blocks
// add styled links to em > a
.text-container {
	ul {
		margin-left: 0;
		padding-left: 0;
		border-top: 1px solid $primary;
		li {
			border-bottom: 1px solid $primary;
			list-style: none;
		}
		li:not(.nav-item) {
			padding: $spacer 0;
		}
	}
	&.text-secondary {
		ul {
			border-top: 1px solid $secondary;
			li {
				border-bottom: 1px solid $secondary;
			}
		}
	}
	em a {
		//font-size: $h5-font-size;
		font-family: $headings-font-family;
		font-style: normal!important;
		text-decoration: none;
		//@extend .arrow-link; // need to look at this
		justify-content: left;
		@include transition($nav-link-transition);
		&::after {
			height: 1rem;
			width: 1rem;
			margin-left: $spacer;
			background: $asset; // default
		}
		&:hover {
			color: $asset; // default
		}
	}
	:last-child {
		margin-bottom: 0!important; // remove the bottom margin at the end of a text container
	}
}