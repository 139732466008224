@import "./src/scss/abstracts/variables";

.award{
    width:370px;
    // @include media-breakpoint-down(lg) {
    //    width: 300px;
    // }
    // @include media-breakpoint-down(md) {
    //     width: 200px;
    //  }
}