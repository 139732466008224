@import "./src/scss/abstracts/variables";

.subnav-sticky {
    position: sticky;
    top: $mt-header-height;
    z-index: 10;
    .nav-item {
        a {
            color: $secondary;
            text-decoration: none;
            @include transition($nav-link-transition);
            &:hover {
                color: $tertiary;
            }
        }
    }

    &-link {
        .nav-link {
            font-size: 1.125rem !important;
            @extend .h5;
            color: $secondary;
            @include transition($nav-link-transition);
            &.active {
                color: $asset;
            }
        }
    }

    &-mobile {
        position: sticky;
        top: $mobile-sticky-top;
        z-index: 11;
    }
}