@use "sass:math";

// change font import
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

//////////////////////////////// BOOTSTRAP ////////////////////////////////

@import "../node_modules/bootstrap/scss/functions";
@import "./src/scss/abstracts/_variables.scss";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/tooltip";



// create responsive versions of position classes
$utilities: map-merge(
  $utilities, (
    "position": map-merge(
      map-get($utilities, "position"),
      ( responsive: true ),
    ),
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (20: 20%, 35: 35%, 70: 70%, 80: 80%),
        ),
      ),
    ),
    "height": map-merge(
      map-get($utilities, "height"),
      (
        values: map-merge(
          map-get(map-get($utilities, "height"), "values"),
          (90: 90%),
        ),
      ),
    ),
	"viewport-height": map-merge(
		map-get($utilities, "viewport-height"),
		(
			values: map-merge(
				map-get(map-get($utilities, "viewport-height"), "values"),
				(80: 80vh),
			),
		),
	),
    "background-color": (
      property: background-color,
      class: bg,
      local-vars: (
        "bg-opacity": 1
      ),
      values: map-merge(
        $utilities-bg-colors,
        (
          "transparent": transparent
        )
      )

    ),
    "color": (
      property: color,
      class: text,
      values: map-merge(
        $theme-colors,
        $brand-colors
      )
    ),
    "border-color": (
      property: border-color,
      class: border,
      values: map-merge(
        $theme-colors,
        $brand-colors
      )
    ),
    "opacity": (
      property: opacity,
      class: opacity,
      values: (
        0: 0,
        100: 100
      ),
      responsive: true
    ),
    "bg-opacity": (
      css-var: true,
      class: bg-opacity,
      values: (
        1:0.01, 
        2:0.02, 
        3:0.03, 
        4:0.04, 
        5:0.05, 
        6:0.06, 
        7:0.07, 
        8:0.08, 
        9:0.09, 
        10:0.1, 
        11:0.11, 
        12:0.12, 
        13:0.13, 
        14:0.14, 
        15:0.15, 
        16:0.16, 
        17:0.17, 
        18:0.18, 
        19:0.19, 
        20:0.2, 
        21:0.21, 
        22:0.22, 
        23:0.23, 
        24:0.24, 
        25:0.25, 
        26:0.26, 
        27:0.27, 
        28:0.28, 
        29:0.29, 
        30:0.3, 
        31:0.31, 
        32:0.32, 
        33:0.33, 
        34:0.34, 
        35:0.35, 
        36:0.36, 
        37:0.37, 
        38:0.38, 
        39:0.39, 
        40:0.4, 
        41:0.41, 
        42:0.42, 
        43:0.43, 
        44:0.44, 
        45:0.45, 
        46:0.46, 
        47:0.47, 
        48:0.48, 
        49:0.49, 
        50:0.5, 
        51:0.51, 
        52:0.52, 
        53:0.53, 
        54:0.54, 
        55:0.55, 
        56:0.56, 
        57:0.57, 
        58:0.58, 
        59:0.59, 
        60:0.6, 
        61:0.61, 
        62:0.62, 
        63:0.63, 
        64:0.64, 
        65:0.65, 
        66:0.66, 
        67:0.67, 
        68:0.68, 
        69:0.69, 
        70:0.7, 
        71:0.71, 
        72:0.72, 
        73:0.73, 
        74:0.74, 
        75:0.75, 
        76:0.76, 
        77:0.77, 
        78:0.78, 
        79:0.79, 
        80:0.8, 
        81:0.81, 
        82:0.82, 
        83:0.83, 
        84:0.84, 
        85:0.85, 
        86:0.86, 
        87:0.87, 
        88:0.88, 
        89:0.89, 
        90:0.9, 
        91:0.91, 
        92:0.92, 
        93:0.93, 
        94:0.94, 
        95:0.95, 
        96:0.96, 
        97:0.97, 
        98:0.98, 
        99:0.99, 
        100:1
      )
    )
  )
);

// UTILITIES

a{
  @include transition($nav-link-transition);
}

.img-loaded{
  opacity: 1;
}

.rounded-xl{
  border-radius: $border-radius-xl;
}

.top-header-height {
	top: $header-height + ($spacer * 1.5);
}


@mixin mask {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}


@import "bootstrap/scss/utilities/api";

@import "./src/scss/base/base";
@import "./src/scss/layout/header";
@import "./src/scss/layout/footer";
@import "./src/scss/components/hero";
@import "./src/scss/components/testimony";
@import "./src/scss/components/team";
@import "./src/scss/components/modal";
@import "./src/scss/components/text";
@import "./src/scss/components/animated";
@import "./src/scss/components/wp-banner";
@import "./src/scss/components/button";
@import "./src/scss/components/posts";
@import "./src/scss/components/table";
@import "./src/scss/components/asset-allocation";
@import "./src/scss/components/blog";
@import "./src/scss/components/form";
@import "./src/scss/components/citations";
@import "./src/scss/components/portal";
@import "./src/scss/components/awards";
