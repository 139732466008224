@import "./src/scss/abstracts/variables";



.social {
    @include transition($nav-link-transition);
    background: $secondary;
    width: $social-size;
    height: $social-size;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center right;
    &:hover {
        background: $tertiary;
    }
    
    &-twitter {
        mask-image: url("images/footer-twitter.svg");
        -webkit-mask-image: url("images/footer-twitter.svg");
    }
    &-youtube {
        mask-image: url("images/footer-youtube.svg");
        -webkit-mask-image: url("images/footer-youtube.svg");
    }
    &-linkedin {
        mask-image: url("images/footer-linkedin.svg");
        -webkit-mask-image: url("images/footer-linkedin.svg");
    }
    &-facebook {
        mask-image: url("images/footer-facebook.svg");
        -webkit-mask-image: url("images/footer-facebook.svg");
    }
    &-instagram {
        mask-image: url("images/footer-instagram.svg");
        -webkit-mask-image: url("images/footer-instagram.svg");
    }
    &-whatsapp {
        mask-image: url("images/footer-instagram.svg");
        -webkit-mask-image: url("images/footer-instagram.svg");
    }
}

.post-banner .social, header .social {
    height:$h4-font-size;
    width:$h4-font-size;
    &:hover {
        background: $primary;
    }
}   

.saltus-logo{
    width: 190px;
    svg{
        fill:$secondary;
        @include transition(fill .15s ease-in-out);
    }
    &:hover {
        svg{
            fill:$tertiary;
        }
    }
}