@import "./src/scss/abstracts/variables";

.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff5e6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    &.bg-secondary {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2318263a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    }
}

.invalid-feedback {
    padding-left: $input-padding-x;   
    &.invalid-pattern{
        display: none!important;
    }
}

.invalid-pattern{
    .invalid-feedback{
        display: none!important;
    }
    .invalid-pattern{
        display: block!important;
    }
}